// 已完成任务-成员任务视图
<template>

    <div class="comMemberView">

        <!-- 组件 表格上方的控制条：导入 新建 同步 等功能按钮 -->
        <com-table-control-bar :showBtn="['ImportTask', 'NewTask', 'SynchroVacation']"
                               :detailRole="detailRole"
                               @update="update"></com-table-control-bar>

        <div class="finisMember memberList">
            <div class="title ofh">
                <div class="fll mr55">{{baseInfo.itemTask}}</div>
                <div class="count fll">
                    <span class="mr35">成员数量：{{baseInfo.memberNum}}</span>
                </div>
            </div>

            <div class="warp">
                <el-collapse v-model="finisMemberTab"
                             @change="changeFinisMemberTab">
                    <el-collapse-item :name="idx"
                                      v-for="(item,idx) in hourList"
                                      :key="idx">
                        <template slot="title">
                            <div class="collapseTitle">
                                <div class="name">{{item.executorName}}</div>
                                <div class="count">{{item.workTaskNum || 0}}</div>
                                <div class="speedBox">
                                    <div class="line">
                                        <el-progress :show-text="false"
                                                     :stroke-width="18"
                                                     :percentage="(item.todayTotalHours/hourList[0].todayTotalHours)*100"></el-progress>
                                    </div>
                                    <div class="time">{{item.todayTotalHours}}（h）</div>
                                </div>
                            </div>
                        </template>
                        <el-table :data="item.workTaskListVoList"
                                  border
                                  stripe>
                            <el-table-column v-for="col in tableData.columns"
                                             :prop="col.id"
                                             :key="col.id"
                                             :label="col.label"
                                             align="center"
                                             :min-width="col.width">
                            </el-table-column>
                            <el-table-column prop="id"
                                             label="操作"
                                             align="center"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <!-- 表格操作组件 -->
                                    <com-table-caozuo :item="scope.row"
                                                      :showBtn="['TaskDetails']"
                                                      :detailRole="detailRole"
                                                      @update="update"></com-table-caozuo>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

    </div>

</template>

<script>
import ComTableControlBar from "../../comTableControlBar";
import ComTableCaozuo from "../../comTableCaozuo";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
export default {
    name: "comMemberView",

    props: {
        detailRole: {
            // 数据权限
            type: Object,
        },
    },

    components: {
        ComTableControlBar,
        ComTableCaozuo,
    },

    data() {
        return {
            finisMemberTab: "", // 工时达标成员 手风琴状态
            notFinisMemberTab: "", // 不达标成员 手风琴状态

            baseInfo: {
                itemTask: "", // 项目名称
                memberNum: "", // 成员数量
                todayMemberNum: "", // 今日参与人数
                todayTotalHours: "", // 今日工时
            },
            hourList: [], // 工时列表
            tableData: {
                columns: [
                    { id: "name", label: "任务名称", width: "100" },
                    { id: "currentHours", label: "总工时（h）", width: "100" },
                    { id: "estimateHours", label: "预估工时", width: "100" },
                    { id: "finishTimeFormat", label: "完成时间", width: "100" },
                ],
            },
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".comMemberView"),
            });
            API.itemMemberIngView({
                status: 1, // 任务状态:0未完成 1已完成
                projectId: this.$route.query.id || null, // 项目Id
            })
                .then((res) => {
                    loading.close();
                    this.baseInfo = {
                        itemTask: res.content.projectName || "", // 项目名称
                        memberNum: res.content.memberNum || 0, // 成员数量
                        todayMemberNum: res.content.todayMemberNum || 0, // 今日参与人数
                        todayTotalHours: res.content.todayTotalHours || 0, // 今日工时
                    };
                    let tableData = res.content.deptTodayWorkTaskTimeVoList;
                    tableData.forEach((item) => {
                        item.workTaskListVoList.forEach((it) => {
                            it.isFinish = it.status ? "完成" : "未完成";
                        });
                    });
                    this.hourList = tableData;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 工时达标成员
        changeFinisMemberTab() {},
        // 不达标成员
        changeNotFinisMember() {},
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comMemberView{

// }
.memberList {
    margin-bottom: 25px;
}
.memberList .title {
    height: 54px;
    padding: 17px 22px;
    background: #9ccdfdba;
    border-radius: 6px 6px 0px 0px;
}
.memberList ::v-deep .el-collapse-item__header {
    position: relative;
}
.memberList ::v-deep .el-collapse-item__header .el-collapse-item__arrow {
    position: absolute;
    left: 10px;
}
.collapseTitle {
    width: 100%;
    overflow: hidden;
    margin: 0 35px;
}
.collapseTitle .name {
    width: 20%;
    float: left;
}
.collapseTitle .count {
    width: 20%;
    float: left;
}
.collapseTitle .speedBox {
    width: 20%;
    float: right;
    position: relative;
}
.collapseTitle .speedBox .line {
    width: 80%;
    position: absolute;
    top: 10px;
}
.collapseTitle .speedBox .time {
    position: absolute;
    width: 20%;
    right: 0;
    text-align: right;
}
</style>
