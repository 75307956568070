// 今日任务-任务视图
<template>

    <div class="comTaskView">
        <div class="searchBar">
            <el-form :model="searchForm"
                     ref="searchForm"
                     :inline="true">
                <el-form-item label="任务名称"
                              prop="name">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入任务名称"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="执行人"
                              prop="executorIds">
                    <el-input v-model="searchForm.executorNames"
                              placeholder="请选择执行人"
                              clearable
                              @clear="cleanexecutorIds"
                              @focus="openselectPerson">
                        <i slot="suffix"
                           v-show="!searchForm.executorIds"
                           class="el-icon-arrow-down"></i>
                    </el-input>
                    <pop-select-members v-if="selectPersonVisible"
                                        :showCheckbox="true"
                                        title="选择执行人"
                                        @close="closeselectPerson"></pop-select-members>
                </el-form-item>
                <el-button type="primary"
                           @click="getList">搜索</el-button>
            </el-form>
        </div>

        <!-- 组件 表格上方的控制条：导入 新建 同步 等功能按钮 -->
        <com-table-control-bar :showBtn="['ImportTask', 'NewTask']"
                               :detailRole="detailRole"
                               @update="update"></com-table-control-bar>

        <div class="listBox">
            <el-table :data="tableData.data"
                      border
                      :height="tableHeight"
                      stripe>
                <el-table-column v-for="col in tableData.columns"
                                 :prop="col.id"
                                 :key="col.id"
                                 :label="col.label"
                                 align="center"
                                 :min-width="col.width">
                </el-table-column>
                <el-table-column prop="id"
                                 label="操作"
                                 align="center"
                                 min-width="100">
                    <template slot-scope="scope">
                        <!-- 表格操作组件 -->
                        <com-table-caozuo :item="scope.row"
                                          :showBtn="['TaskDetails', 'Del']"
                                          :detailRole="detailRole"
                                          @update="update"></com-table-caozuo>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination :page="searchForm.page"
                        :limit="searchForm.size"
                        :total="pageTotal"
                        @pagination="paginationChange" />
        </div>

    </div>

</template>

<script>
import ComTableCaozuo from "../../comTableCaozuo";
import ComTableControlBar from "../../comTableControlBar";
import PopSelectMembers from "../../../../../components/selectTree/popSelectMembers";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
import Pagination from "@/components/Pagination"; // 分页

export default {
    name: "comTaskView",

    props: {
        detailRole: {
            // 数据权限
            type: Object,
        },
    },

    components: {
        ComTableCaozuo,
        ComTableControlBar,
        PopSelectMembers,
        Pagination,
    },

    data() {
        return {
            selectPersonVisible: false, // 选择执行人弹窗显示

            searchForm: {
                name: "", // 任务名称
                executorIds: [], // 执行人
                executorNames: "", // 执行人名字
                projectId: this.$route.query.id || null, // 项目Id
                page: 1,
                size: 20,
            },
            pageTotal: 0,

            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "任务名称", width: "100" },
                    { id: "executorName", label: "任务执行人", width: "100" },
                    {
                        id: "estimateHours",
                        label: "预计工时（h）",
                        width: "100",
                    },
                    {
                        id: "currentHours",
                        label: "实际工时（h）",
                        width: "100",
                    },
                    { id: "todayHours", label: "今日工时（h）", width: "100" },
                    { id: "isFinish", label: "是否完成", width: "100" },
                ],
            },
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            // 获取列表
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".listBox"),
            });
            API.itemTaskView(this.searchForm)
                .then((res) => {
                    loading.close();
                    let tableData = res.content.records;
                    tableData.forEach((item) => {
                        item.isFinish = item.status ? "完成" : "未完成";
                        item.sonCount = item.allSubTaskNum
                            ? `${item.allSubTaskNum}/${item.finishSubTaskNum}`
                            : 0;
                    });
                    this.tableData.data = tableData;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 选择选择执行人经理
        openselectPerson() {
            this.selectPersonVisible = true;
        },
        // 关闭选择选择执行人经理弹框
        closeselectPerson({ idArr, names }) {
            if (idArr) {
                this.searchForm.executorIds = idArr;
                this.searchForm.executorNames = names;
            }
            this.selectPersonVisible = false;
        },
        // 清空执行人
        cleanexecutorIds() {
            this.searchForm.executorIds = [];
            this.searchForm.executorNames = "";
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.page = e.page;
            this.searchForm.size = e.size;
            this.getList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 420 });
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comTaskView{

// }
</style>
