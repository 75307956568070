// 项目任务
<template>
    <div class="itemTask baseBg">
        <backUp v-if="needGoBack"></backUp>
        <div class="tabBox">
            <div class="header">
                <el-tabs v-model="activeName"
                         type="border-card"
                         @tab-click="handleClick">
                    <el-tab-pane label="今日任务"
                                 name="今日任务"></el-tab-pane>
                    <el-tab-pane label="进行中任务"
                                 name="进行中任务"></el-tab-pane>
                    <el-tab-pane label="已完成任务"
                                 name="已完成任务"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="tabContentBox">
                <!-- 今日任务 -->
                <div class="todayTask"
                     v-if="activeName === '今日任务'">
                    <com-todaytask :detailRole="detailRole"></com-todaytask>
                </div>
                <!-- 进行中任务 -->
                <div class="ingTask"
                     v-if="activeName === '进行中任务'">
                    <com-ingtask :detailRole="detailRole"></com-ingtask>
                </div>
                <!-- 已完成任务 -->
                <div class="didTask"
                     v-if="activeName === '已完成任务'">
                    <com-didtask :detailRole="detailRole"></com-didtask>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ComDidtask from "./components/itemTask/comDidtask/index";
import ComIngtask from "./components/itemTask/comIngtask/index";
import ComTodaytask from "./components/itemTask/comTodaytask/index";
import backUp from "@/components/backUp";
import userRoleApi from "@/api/userRoleApi.js";
export default {
    name: "itemTask",

    props: [],

    components: {
        ComDidtask,
        ComIngtask,
        ComTodaytask,
        backUp,
    },

    data() {
        return {
            activeName: "今日任务",
            needGoBack: false,
            detailRole: {}, //权限数据
        };
    },

    created() {},

    mounted() {
        this.needGoBack = this.$route.query.needGoBack;

        userRoleApi
            .getCurrentButtonByMenuId("1332274331730391041")
            .then((res) => {
                this.detailRole = this.commonJs.tabIsAlive(
                    res.content,
                    "项目任务"
                );
            });
    },

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>
