// 项目任务-今日任务
<template>

    <div class="comTodayTask">
        <div class="tabTable blueLine">
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
                <el-tab-pane label="成员任务视图"
                             name="成员任务视图"></el-tab-pane>
                <el-tab-pane label="任务视图"
                             name="任务视图"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="tabContentBox">
            <!-- 成员任务视图 -->
            <div class="memberView"
                 v-if="activeName === '成员任务视图'">
                <com-member-view :detailRole="detailRole"></com-member-view>
            </div>
            <!-- 任务视图 -->
            <div class="taskView"
                 v-if="activeName === '任务视图'">
                <com-task-view :detailRole="detailRole"></com-task-view>
            </div>
        </div>
    </div>

</template>

<script>
import ComTaskView from "./comTaskView";
import ComMemberView from "./comMemberView";
export default {
    name: "comTodayTask",

    props: {
        detailRole: {
            // 数据权限
            type: Object,
        },
    },

    components: {
        ComTaskView,
        ComMemberView,
    },

    data() {
        return {
            activeName: "成员任务视图",
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 切换Tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// .comTodayTask{

// }
</style>
